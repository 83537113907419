import React, { FC, useState, useEffect, ChangeEventHandler } from 'react';
import { css } from "@emotion/react";
import { Drawer, makeStyles } from '@material-ui/core';
import { useForm } from "react-hook-form";

import useMENAUser from '../../hooks/useMENAUser';
import FormCheck from './FormCheck';

const useStyle = makeStyles(() => ({
  drawer: {
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  }
}))

const Terms: FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { register, handleSubmit, watch, setValue } = useForm();
  const { terms_all, term1, term2 } = watch();
  const classes = useStyle();
  const { user: { name }} = useMENAUser();

  const onSubmit = () => {
    setIsOpen(false);
  }

  const handleAgreeAll: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.checked;
    setValue('term1', value);
    setValue('term2', value);
  };

  useEffect(() => {
    setValue('terms_all', term1 && term2);
  }, [term1, term2, setValue])

  useEffect(() => {
    name && setIsOpen(false);
  }, [name]);

  return (
      <Drawer
        anchor='bottom'
        open={isOpen}
        classes={{
          paperAnchorBottom : classes.drawer,
        }}
      >
        <form 
          css={styles.form} 
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormCheck
            name='terms_all'
            label='الشروط والأحكام'
            ref={register({ required: true })}
            onChange={handleAgreeAll}
            containerStyle={styles.agreeAll}
          />
          <FormCheck
            ref={register({ required: true })}
            name='term1'
            label='لقد قرأت ووافقت على الشروط والأحكام'
            href="https://azarlive.com/home/privacypolicy.html"
            showArrow
          />
          <FormCheck
            ref={register({ required: true })}
            name='term2'
            label='أوافق على تلقي رسائل البريد الإلكتروني و/أو الرسائل النصية القصيرة بخصوص خاصية دعوة الأصدقاء'
          />
          <button
            type="submit"
            disabled={!terms_all}
          >
            تأكيد
          </button>
        </form>
      </Drawer>
  )
}

const styles = {
  form : css`
    padding: 24px;
  `,
  agreeAll: css`
    height: 56px;
    border: 1px solid #efefef;
    border-radius: 16px;
    margin-bottom: 6px;
  
    & label span {
      font-size: 16px;
    }
  `,
};

export default Terms;
