import React, { FC } from 'react';
import { Router } from '@reach/router';
import { Helmet } from "react-helmet";

import Form from '../../components/mena/Form';
import Complete from '../../components/mena/Complete';
import Invalid from '../../components/mena/Invalid';
import Participated from '../../components/mena/Participated';
import { MENAUserProvider } from '../../hooks/useMENAUser';

const MENAInviteFriendsPage: FC = () => {
  return (
    <MENAUserProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>Azar</title>
      </Helmet>
      <Router basepath="/mena">
        <Form path="/form" />
        <Complete path="/complete" />
        <Invalid path="/invalid" />
        <Participated path="/participated" />
      </Router>
    </MENAUserProvider>
  );
}

export default MENAInviteFriendsPage;
