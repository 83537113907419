import React, { FC } from 'react';
import { css, Global, Interpolation, Theme } from "@emotion/react";

interface Props {
  pageTitle?: string;
  styles?: Interpolation<Theme>;
}

const Layout: FC<Props> = ({ 
  pageTitle,
  children,
  styles,
}) => {
  return (
    <>
      <Global styles={[css`
        * {
          box-sizing: border-box;
          padding: 0;
          margin: 0;
          font-family: 'SF Pro Display', Roboto, sans-serif;
        }
        body { 
          margin: 0;
          background-color: #000;
        }
        main {
          max-width: 640px;
          min-height: 100vh;

          margin: 0 auto;

          background-color: #fff;
        }
        a:visited, a:-webkit-any-link {
            text-decoration: none;
          }
      `, styles]} />
      <main>
        {pageTitle && <title>{pageTitle}</title>}
        {children}
      </main>
    </>
  )
}

export default Layout;
