import React, { FC } from 'react';
import { css, Interpolation, Theme } from "@emotion/react";

import DefaultImage from '../images/imgProfileDefault11.svg';

interface Props {
  image?: React.ReactNode;
  title?: string;
  description?: string;
  style?: Interpolation<Theme>;
}

const ImagePlaceholder: FC<Props> = ({
  image = <DefaultImage css={styles.defaultImage} />,
  title,
  description,
  style,
}) => {
  return (
    <div css={[styles.container, style]}>
      {image}
      {title && <div css={styles.title}>{title}</div>}
      {description && <div css={styles.description}>{description}</div>}
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;

  `,
  defaultImage: css`
    width: 170px;
    height: 170px;
    border-radius: 100%;
  `,
  title: css`
    margin-top: 18px;

    font-size: 20px;
    font-weight: 700;

    color: #222222;
  `,
  description: css`
    margin-top: 16px;

    color: #9C9C9C;
  `,
}

export default ImagePlaceholder;
