import React, { FC } from 'react';
import { css } from "@emotion/react";
import { RouteComponentProps, navigate } from '@reach/router'

import Layout from '../Layout';
import ImagePlaceholder from '../ImagePlaceholder';
import Button from '../Button';

// 이미 참여한 사용자
const MENAParticipatedPage: FC<RouteComponentProps> = () => {

  const handleClick = () => {
    navigate('/mena/form');
  };

  return (
    <Layout
      styles={styles.layout}
    >
      <ImagePlaceholder
        style={styles.placeholder}
        description="أنت بالفعل مشترك في هذا العرض"
      />
      <div css={styles.description}>
      <div css={styles.descriptionTitle}>يرجى القراءة!</div>
        <ul css={styles.ul}>
          <li>أهلاً بك,\n
قم بدعوة صديقك على أزار و احصل على قسيمة شراء من نون بقيمة 10دولار.\n
عند قبول صديقك الدعوة و تسجيله في أزار سنقوم على الفور بإرسال هديتك (قسيمة الشراء). \n
سيحصل صديقك بالتالي على قسيمة شراء من نون أيضاً بقيمة 5 دولار.\n
 يرجى منك قبل البدء بدعوة أصدقائك، القيام بملئ المعلومات الشخصية الخاصة بك أدناه.\n
تأكد أن معلوماتك الشخصية سيتم استخدامها فقط من أجل عملية تسليم المكافآه لك (قسيمة الشراء) \n
لن يتم احتساب أي عملية دعوة لصديق تمت دعوته من قبل, الرجاء دعوة أصدقاء جدد*\n
قسائم الشراء محدودة ل1000 مستخدم فقط! على صديقك قبول الدعوة حتى تتمكن من الحصول على قسيمة الشراء الخاصة بك *</li>
          <li>بمجرد تقديم معلوماتك الشخصية، لن تتمكن من إجراء المزيد من التعديلات.</li>
          <li>انتبه عند ملئ خانة المعلومات الشخصية الخاصة بك / لن نتمكن من المساعدة إذا كان هناك مشكلة بسبب أخطاء في المعلومات الشخصية المدخلة.</li>
        </ul>
      </div>
      <Button styles={styles.button} onClick={handleClick}>تعديل البيانات الشخصية</Button>
    </Layout>
  )
}

const styles = {
  layout: css`
    body {
      direction: rtl;
    }

    main {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 24px;
    }
  `,
  placeholder: css`
    margin: auto 0;
  `,
  description: css`
    padding: 24px 16px;
    border-top: 1px solid #EFEFEF;
    border-radius: 16px;

    background-color: #F8F8F8
  `,
  descriptionTitle: css`
    font-weight: 700;
    
    color: #4A4A4A;
  `,
  ul: css`
  margin-top: 8px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #858585;

  & li::marker {
    content: '･ ';
  }
`,
  button: css`
    margin-top: 24px;
  `,
}

export default MENAParticipatedPage;
