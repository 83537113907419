import React, { FC, createContext, useContext, useState, useEffect } from 'react';
import { useLocation, navigate } from '@reach/router';
import store from 'store';

import * as menaApis from '../apis/mena';
import useQuery from './useQuery';

interface User {
  username: string;
  name: string;
  email: string;
  phone: string;
  address_line: string;
  city: string;
  country: string;
  post_code: string;
}

type MENAUser = Partial<User>;

const STORE_KEY = 'user';

interface MENAUserContextValues {
  user: MENAUser,
  setUser?: React.Dispatch<React.SetStateAction<MENAUser>>;
}

const MENAUserContext = createContext<MENAUserContextValues>({ user: {} });

export const MENAUserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<MENAUser>({});
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    const username = store.get(STORE_KEY);
    if(username) {
      setUser(prev => ({...prev, username }));
    }
  },[]);

  useEffect(() => {
    if(query.username) {
      store.set(STORE_KEY, query.username);
      setUser(prev => ({...prev, username: query.username as string}));
      navigate(location.pathname, { replace: true });
    } else {
      const isValid = !!store.get(STORE_KEY);
      !isValid && navigate('/mena/invalid', { replace: true })
    }
  }, [query.username, location.pathname]);

  useEffect(() => {
    const username = user.username;

    if(!username) {
      return;
    }
    
    const getIsParticipatedUser = () =>
      menaApis
        .getIsParticipatedUser(username)
        .then(({ username, ...user }) => {
          if(!username) {
            navigate('/mena/form', { replace: true });
          } else {
            setUser({ username, ...user });
            navigate('/mena/participated', { replace: true });
          }
        });
    
    getIsParticipatedUser();
  }, [user.username]);

  return (
    <MENAUserContext.Provider
      value={{ user, setUser }}
    >
      {children}
    </MENAUserContext.Provider>
  )

};

const useMenaUser = () : MENAUserContextValues => useContext(MENAUserContext);


export default useMenaUser;