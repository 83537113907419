/* eslint-disable react/display-name */
import React, { 
  forwardRef,
  useCallback, 
  InputHTMLAttributes, 
  TouchEventHandler,
} from 'react';
import { useFormContext } from "react-hook-form";
import { css } from "@emotion/react";
import { cx } from '@emotion/css';

import ClearIcon from '../../images/mena/icCloseCircle.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  name: string;
  active?: boolean;
  handleActive?: (name: string) => void;
}

const Input = forwardRef<HTMLInputElement,Props>(({
  label,
  name,
  placeholder,
  errorMessage,
  type = "text",
  active,
  handleActive,
  ...props
}, ref) => {
  const { watch, setValue, formState: { dirtyFields } } = useFormContext();
  const value = watch(name);
  const isBig = active || !!value;
  const showClearIcon = active && dirtyFields[name];

  const handleTouchEnd: TouchEventHandler = useCallback((e) => {
    handleActive && handleActive(name);
    e.stopPropagation();
  }, [handleActive, name]);

  const handleClickClear: TouchEventHandler = useCallback(() => {
    name && setValue(name, '');
  },[name, setValue]);

  return (
    <>
      <div 
        css={styles.container} 
        className={cx({ 
          focus: active,
          error: !!errorMessage,
          big: isBig,
        })}
        onTouchEnd={handleTouchEnd}
      >
        {isBig && label && <label>{label}</label>}
        <input
          ref={ref}
          name={name}
          type={type}
          placeholder={active ? '' : (placeholder || label)}
          {...props}
        />
        {showClearIcon && <ClearIcon css={styles.clear} onTouchEnd={handleClickClear} />}
      </div>
      {errorMessage && <span css={styles.error}>{errorMessage}</span>}
    </>
  )
});

const styles = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 56px;

    margin-top: 12px;

    border-radius: 16px;

    background-color: #F8F8F8;

    &.big {
      height: 76px;
    }

    &.focus {
      border: 1px solid #00DD99;
    }

    &.error {
      border: 1px solid #FF3F3F;
    }

    & label {
      margin: 13px 16px 0 0;
      font-size: 12px;

      color: #B4B4B4;
      text-transform: capitalize;
    }

    & label.hidden {
      opacity: 0;
    }

    & input {
      position: absolute;
      width: calc(100% - 32px);
      height: calc(100% - 24px);
      margin: 12px 16px;
      padding-top: 16px;

      border: none;
      border-radius: 16px;
      outline: none;

      color: #22222;
      font-weight: bold;

      caret-color: #00DD99;
    }

    & input::placeholder {
      color: #b4b4b4;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: normal;
    }
  `,
  clear: css`
    width: 24px;
    height: 24px;

    position: absolute;
    top: 36px;
    left: 16px;
  `,
  error: css`
    display: block;
    margin-top: 8px;
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400;
    
    color: #FF3F3F;
  `,
};

export default Input
