import axios from './utils';

interface User {
 username: string;
 name: string;
 email: string;
 phone: string;
 address_line: string;
 city: string;
 country: string;
 post_code: string;
}

export const registerUser = (user: User) => axios.post<undefined>('/mena/users', { user });

export const getIsParticipatedUser = (username: string) => 
 axios.get<User>(`/mena/users/${username}`)
  .then(res => res.data);

export const updateUser = (user: User) =>
 axios.put<undefined>(`/mena/users/${user.username}`, { user });
