import React, { FC, useCallback, useState, useEffect } from 'react'
import { css } from "@emotion/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { navigate, RouteComponentProps } from '@reach/router';

import useMenaUser from '../../hooks/useMENAUser';
import * as menaApis from '../../apis/mena';

import Layout from '../Layout';
import Input from './Input';
import Terms from './Terms';
import GiftIcon from '../../images/mena/icGift.svg';

interface FormPayload {
  username: string;
  name: string;
  email: string;
  phone: string;
  address_line: string;
  city: string;
  country: string;
  post_code: string;
}

const MENAInviteFriendsPage: FC<RouteComponentProps> = () => {
  const [activeInput, setActiveInput] = useState('');
  const { user } = useMenaUser();
  const methods = useForm({ mode: 'onBlur', defaultValues: user });
  const { register, handleSubmit, errors, formState: { isValid } } = methods;

  const handleActive = useCallback((name: string) => {
    setActiveInput(name);
  },[]);

  // todo: 400 error, fix form username undefined
  const onSubmit: SubmitHandler<FormPayload> = useCallback(
    async (data) => {
      const apis = user.name ? menaApis.updateUser : menaApis.registerUser;
      await apis({
        ...data,
        username: user.username || '',
      })
      .then(() => navigate(
        '/mena/complete',
        { replace: true },
      ))
    },
    [user.username, user.name],
  );

  useEffect(() => {
    const callback = () => {
      setActiveInput('');
    };
    window.addEventListener('touchend', callback)

    return () => {
      window.removeEventListener('touchend', callback);
    }
  })

  return (
    <Layout
      styles={styles.global}
    >
      <h2 css={styles.h2}>
      قم بدعوة صديق لك على أزار و احصل على قسيمة شراء مجانية من موقع نون بقيمة 50 ريال!
      </h2>
      <p css={styles.paragraph}>
        <GiftIcon css={styles.giftIcon} />
        قسيمة شراء من موقع نون بقيمة 50 ريال!
      </p>

      <div css={styles.description}>
        <div css={styles.descriptionTitle}>يرجى القراءة!</div>
        <ul css={styles.ul}>
          <li>أهلاً بك,
قم بدعوة صديقك على أزار و احصل على قسيمة شراء من نون بقيمة 50 ريال.
عند قبول صديقك الدعوة و تسجيله في أزار سنقوم على الفور بإرسال هديتك (قسيمة الشراء) .
سيحصل صديقك بالتالي على قسيمة شراء من نون أيضاً بقيمة 25 ريال.
يرجى منك قبل البدء بدعوة أصدقائك، القيام بملئ المعلومات الشخصية الخاصة بك أدناه.
تأكد أن معلوماتك الشخصية سيتم استخدامها فقط من أجل عملية تسليم المكافآه لك (قسيمة الشراء) 
لن يتم احتساب أي عملية دعوة لصديق تمت دعوته من قبل, الرجاء دعوة أصدقاء جدد*
قسائم الشراء محدودة ل1000 مستخدم فقط! على صديقك قبول الدعوة حتى تتمكن من الحصول على قسيمة الشراء الخاصة بك</li>
          <li>بمجرد تقديم معلوماتك الشخصية، لن تتمكن من إجراء المزيد من التعديلات.</li>
          <li>انتبه عند ملئ خانة المعلومات الشخصية الخاصة بك / لن نتمكن من المساعدة إذا كان هناك مشكلة بسبب أخطاء في المعلومات الشخصية المدخلة.</li>
        </ul>
      </div>
      <FormProvider { ...methods} >
        <form css={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            ref={register({ required: true })}
            name="name"
            label="الاسم"
            active={activeInput === 'name'}
            handleActive={handleActive}
          />
          <Input
            ref={register({ 
              required: true,
              pattern: /^[0-9]{7,14}$/i
            })}
            name="phone"
            label="رقم الهاتف"
            type="tel"
            errorMessage={errors.phone && 'رقم هاتف خاطئ'}
            active={activeInput === 'phone'}
            handleActive={handleActive}
          />
          <Input
            ref={register({ 
              required: true,
              pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
            })}
            name="email"
            label="البريد الإلكتروني"
            type="email"
            errorMessage={errors.email && 'بريد إلكتروني خاطئ'}
            active={activeInput === 'email'}
            handleActive={handleActive}
          />
          <fieldset>
            <legend>العنوان</legend>
            <Input
              ref={register({ required: true })}
              name="address_line"
              label="العنوان"
              active={activeInput === 'address_line'}
              handleActive={handleActive}
            />
            <Input
              ref={register({ required: true })}
              name="city"
              label="المدينة"
              active={activeInput === 'city'}
              handleActive={handleActive}
            />
            <Input
              ref={register({ required: true })}
              name="country"
              label="البلد"
              active={activeInput === 'country'}
              handleActive={handleActive}
            />
          </fieldset>
          <fieldset>
            <legend>حساب المستخدم</legend>
            <Input
              ref={register()}
              name="username"
              value={user.username}
              disabled
            />
          </fieldset>
          <button type="submit" disabled={!isValid}>{user.name ? 'تعديل البيانات الشخصية' : 'تسجيل'}</button>
        </form>
      </FormProvider>
      <Terms />
    </Layout>
  )
}

const styles= {
  global: css`
    body {
      direction: rtl;
    }

    main {
      padding: 0 24px 24px 24px;
    }

    [type=submit] {
      width: 100%;
      height: 56px;

      margin-top: 40px;

      border: none;
      border-radius: 28px;

      font-size: 16px;
      font-weight: 700;
      color: #fff;
      background-color: #00DD99;
    }

    [type=submit]:disabled {
      background-color: #EFEFEF;
    }
  `,
  h2: css`
    max-width: 247px;
    padding-top: 40px;
    
    font-size: 24px;
    font-weight: 700;
    line-height: 30px; 

    word-break: keep-all;
  `,
  paragraph: css`
    display: flex;
    align-items: center;
    margin-top: 16px;

    font-size: 14px;
    font-weight: 400;
    color: #858585;
  `,
  giftIcon: css`
    width: 24;
    height: 24;
    margin-left: 4px;
  `,
  description: css`
    margin-top: 48px;
    padding-top: 32px;
    border-top: 1px solid #EFEFEF;
  `,
  descriptionTitle: css`
    font-weight: 700;
    
    color: #4A4A4A;
  `,
  ul: css`
    margin-top: 8px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #858585;

    & li::marker {
      content: '･ ';
    }
  `,
  form: css`
    margin-top: 56px;
    padding-top: 20px;

    border-top: 16px solid #F8F8F8; //todo: long

    & input {
      background-color: transparent;
    }

    & fieldset {
      border: none;
      margin-top: 32px;
    }

    & legend {
      font-weight: bold;
      color: #9c9c9c;
      text-transform: capitalize;
      font-size: 14px;
    }
  `,
}

export default MENAInviteFriendsPage;
