/* eslint-disable react/display-name */
import React, {
  InputHTMLAttributes,
  forwardRef
} from 'react';
import { css, Interpolation, Theme } from "@emotion/react";

import notCheckedIcon from '../../images/mena/not-checked-icon.png';
import checkedIcon from '../../images/mena/checked-icon.png';
import ArrowIcon from '../../images/icChevronRight.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  href?: string;
  containerStyle?:Interpolation<Theme>,
  showArrow?: boolean;
}

const FormCheck = forwardRef<HTMLInputElement, Props>(({
  label,
  name,
  href,
  containerStyle,
  showArrow,
  ...props
}, ref) => {
  return (
    <div css={[styles.container, containerStyle]}>
      <input
        id={name}
        ref={ref}
        type="checkbox"
        name={name}
        {...props}
      />
      <label htmlFor={name}>
        <span>{href ? <a href={href} target="_blank" rel="noreferrer">{label}</a> : label}</span>
        {showArrow && <ArrowIcon className='arrow' />}
      </label>
    </div>
  )
});

const styles = {
  container : css`
    display: flex;

    width: 100%;
    height: 36px;

    margin-top: 2px;

    color: #858585;

    & input[type=checkbox] {
      display: none;
    }

    & input[type=checkbox] + label {
      display: flex;
      align-items: center;
      margin-right: 18px;
      padding-right: 34px;
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: center;
      background-image: url('${notCheckedIcon}');

      font-size: 12px;
    }

    & input[type=checkbox]:checked + label {
      background-image: url('${checkedIcon}');
    }

    & a:visited, a:link {
      color: #858585;
    }

    & .arrow {
      margin-right: 2px;
      transform: scaleX(-1);
    } 
  `,
}

export default FormCheck
