import React, { FC } from 'react';
import { css } from "@emotion/react";
import { RouteComponentProps } from '@reach/router'

import Layout from '../Layout';
import ImagePlaceholder from '../ImagePlaceholder';
import Button from '../Button';

import DoneImage from '../../images/mena/imgReferralMenaEventDone.svg';

const MENACompletePage: FC<RouteComponentProps> = () => {
  return (
    <Layout
      styles={styles.layout}
    >
      <ImagePlaceholder
        style={styles.placeholder}
        image={<DoneImage />}
        title="التسجيل اكتمل!"
        description="هل من الممكن الآن أن نقوم بدعوة الأصدقاء؟"
      />
      <Button>
        <a href='azarlive://app.com/'>
          <span>
          الرجوع إلى أزار
          </span>
        </a>
      </Button>
    </Layout>
  )
}

const styles = {
  layout: css`
    body {
      direction: rtl;
    }

    main {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 24px;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a:visited, a:-webkit-any-link {
      color: #fff;
    }
  `,
  placeholder: css`
    margin: auto 0;
  `,
}

export default MENACompletePage;
