import React, {
  FC,
  ButtonHTMLAttributes,
} from 'react';

import { css, Interpolation, Theme } from "@emotion/react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: Interpolation<Theme>;
}

const Button: FC<Props> = ({
  children,
  styles,
  ...props
}) => {
  return (
    <button
      css={[style, styles]}
      {...props}
    >
      {children}
    </button>
  )
}

const style = css`
  width: 100%;
  height: 56px;

  border: none;
  border-radius: 28px;

  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #00DD99;

  &:disabled {
    background-color: #EFEFEF;
  }
`;

export default Button;
