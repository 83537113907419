import React, { FC } from 'react';
import { css } from "@emotion/react";
import { RouteComponentProps } from '@reach/router';

import Layout from '../Layout';
import ImagePlaceholder from '../ImagePlaceholder';

const MENAInvalidPage: FC<RouteComponentProps> = () => {
  return (
    <Layout
      styles={css`
        main {
          display: flex;
          align-items: center;
        }

        body {
          direction: rtl;
        }
      `}
    >
      <ImagePlaceholder
        description="يوجد مشكلة / لا يمكن التسجيل"
      />
    </Layout>
  )
}

export default MENAInvalidPage;
